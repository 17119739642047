
import { Component, Vue } from 'vue-property-decorator'
import LsDialog from '@/components/ls-dialog.vue'
import LsPagination from '@/components/ls-pagination.vue'
import AddPackageCategory from '@/components/goods/add-package-category.vue'
import { apiPackageCategoryDel, apiPackageCategoryLists } from '@/api/goods'
import { RequestPaging } from '@/utils/util'
@Component({
    components: {
        LsDialog,
        LsPagination,
        AddPackageCategory
    }
})
export default class PackageCategory extends Vue {
    $refs!: { AddPackageCategory: any }
    loading = false
    pager = new RequestPaging()
    form: any = {
        name: '',
		image:'',
        sort: ''
    }
    lists = []
    count = 0

    handleAdd() {
        this.form = {
            name: '',
			image: '',
            sort: ''
        }
        this.$refs.AddPackageCategory.openDialog()
    }
    handleEdit({ id, name, image, sort }: any) {
        this.form = {
            id,
            name,
            image,
            sort
        }
        this.$refs.AddPackageCategory.openDialog()
    }

    handleDelete(id: number) {
        apiPackageCategoryDel(id).then(() => {
            this.getList()
        })
    }

    getList() {
        this.pager
            .request({
                callback: apiPackageCategoryLists
            })
            .then((res: any) => {
                this.lists = res?.lists
                this.count = res?.count
            })
    }

    created() {
        this.getList()
    }
}
